import React from 'react';
import { FelaComponent } from 'react-fela';

import withPageContext from '../pageContext';

import SEO from '../components/SEO';
import HeaderScroller from '../components/HeaderScroller';
import HeaderSection from '../components/Event/HeaderSection';
import FeaturesSection from '../components/Event/FeaturesSection';
import BrandingSection from '../components/Event/BrandingSection';
import MoreFeaturesSection from '../components/Event/MoreFeaturesSection';
import FeedbackSection from '../components/Event/FeedbackSection';
import ClientsSection from '../components/Event/ClientsSection';
import ContactsSection from '../components/Event/ContactsSection';
import Footer from '../components/Footer';

// Header assets
import peopleIcon from '../images/people.svg';

/* import chatImage from '../images/features/chat_pics.jpg';
import questionnaireImage from '../images/features/questionnaire.jpg'; */

// Branding assets
import brandingImage from '../images/img_branding_conf_and_fest@2x.png';

// More features assets
import adminIcon from '../images/key-features-icons/ic_admin.svg';
import feedbackIcon from '../images/key-features-icons/ic_sharing.svg';
import languagesIcon from '../images/key-features-icons/ic_lang.svg';
import promoteIcon from '../images/key-features-icons/ic_brand.svg';
import statisticsIcon from '../images/key-features-icons/ic_stats.svg';
import visitorsIcon from '../images/key-features-icons/ic_info.svg';

// Feedback assets
import ohpScreen1 from '../images/testimonials/ohp_menu.png';
import ohpScreen2 from '../images/testimonials/ohp_home.png';
import ohpAvatar from '../images/faces/img_face_ohp.png';

import deloitteScreen1 from '../images/testimonials/deloitte_homepage.jpg';
import deloitteScreen2 from '../images/testimonials/deloitte_timeline.jpg';
import deloitteAvatar from '../images/faces/img_face_deloitte.png';

// Clients assets
import openhouseLogo from '../images/logos/openhouse.svg';
import deloitteLogo from '../images/logos/deloitte.svg';
import mdevcampLogo from '../images/logos/mdevcamp.svg';
import techedLogo from '../images/logos/teched.svg';
import wt100Logo from '../images/logos/wt100.svg';
import cifLogo from '../images/logos/cif.svg';
import velVedyLogo from '../images/logos/vel_vedy.svg';
import modelicaLogo from '../images/logos/modelica.svg';
import iinfoLogo from '../images/logos/iinfo.svg';

// Assets assets
import { graphql, useStaticQuery } from 'gatsby';
import { LinkType } from '../components/Link/Link.style';
import useFeatures from '../components/Features/useFeatures';

const links = [
    { to: '/', labelId: 'home.menu.top', type: LinkType.GATSBY },
    { to: '/features', labelId: 'home.menu.features', type: LinkType.GATSBY },
    { to: '/festival', labelId: 'home.menu.festival', type: LinkType.GATSBY },
    { to: '/conference', labelId: 'home.menu.conference', type: LinkType.GATSBY },
    { to: '#contacts', labelId: 'home.menu.contacts', asButton: true, type: LinkType.NATIVE },
];

const info = [
    { titleId: 'conference.header.info.1.title', descId: 'conference.header.info.1.desc' },
    { titleId: 'conference.header.info.2.title', descId: 'conference.header.info.2.desc' },
    { image: peopleIcon, descId: 'conference.header.info.3.desc' },
    { titleId: 'conference.header.info.4.title', descId: 'conference.header.info.4.desc' },
];

const moreFeatures = [
    {
        titleId: 'conference.moreFeatures.1.title',
        descId: 'conference.moreFeatures.1.desc',
        image: (
            <img
                src={visitorsIcon}
                alt=""
                role="presentation"
                style={{ width: '64px', height: '65px', marginTop: '1px' }}
            />
        ),
    },
    {
        titleId: 'conference.moreFeatures.2.title',
        descId: 'conference.moreFeatures.2.desc',
        image: (
            <img
                src={feedbackIcon}
                alt=""
                role="presentation"
                style={{ width: '70px', height: '60px', marginTop: '7px' }}
            />
        ),
    },
    {
        titleId: 'conference.moreFeatures.3.title',
        descId: 'conference.moreFeatures.3.desc',
        image: <img src={promoteIcon} alt="" role="presentation" style={{ width: '100px', height: '65px' }} />,
    },
    {
        titleId: 'conference.moreFeatures.4.title',
        descId: 'conference.moreFeatures.4.desc',
        image: <img src={languagesIcon} alt="" role="presentation" style={{ width: '79px', height: '61px' }} />,
    },
    {
        titleId: 'conference.moreFeatures.5.title',
        descId: 'conference.moreFeatures.5.desc',
        image: (
            <img
                src={adminIcon}
                alt=""
                role="presentation"
                style={{ width: '84px', height: '60px', marginTop: '3px' }}
            />
        ),
    },
    {
        titleId: 'conference.moreFeatures.6.title',
        descId: 'conference.moreFeatures.6.desc',
        image: (
            <img
                src={statisticsIcon}
                alt=""
                role="presentation"
                style={{ width: '77px', height: '56px', marginTop: '2px' }}
            />
        ),
    },
];

const feedback = [
    {
        authorId: 'conference.feedback.1.author',
        textId: 'conference.feedback.1.text',
        avatar: ohpAvatar,
        screen1: ohpScreen1,
        screen2: ohpScreen2,
    },
    {
        authorId: 'conference.feedback.2.author',
        textId: 'conference.feedback.2.text',
        avatar: deloitteAvatar,
        screen1: deloitteScreen1,
        screen2: deloitteScreen2,
    },
];

const clients = [
    { logo: openhouseLogo, name: 'Open House Praha' },
    { logo: deloitteLogo, name: 'Deloitte.' },
    { logo: mdevcampLogo, name: 'mDevCamp' },
    { logo: techedLogo, name: 'TechEd DevCon' },
    { logo: wt100Logo, name: 'WebTop100' },
    { logo: cifLogo, name: 'Czech Innovation Festival' },
    { logo: velVedyLogo, name: 'Veletrh Vědy' },
    { logo: modelicaLogo, name: 'Modelica' },
    { logo: iinfoLogo, name: 'Internet Info' },
];

const style = ({ theme }) => ({
    minHeight: '100vh',
    backgroundColor: theme.colors.black,
});

const IndexPage = () => {
    const { header } = useStaticQuery(
        graphql`
            query {
                header: file(relativePath: { eq: "img_header_conf.jpg" }) {
                    childImageSharp {
                        fluid(quality: 95, maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `,
    );

    const features = useFeatures();

    return (
        <FelaComponent style={style}>
            <SEO title="title.conference" desc="description.conference" />
            <HeaderScroller links={links} />
            <main>
                <HeaderSection
                    titleId="conference.header.title"
                    backgroundImage={header.childImageSharp.fluid}
                    info={info}
                />
                <FeaturesSection
                    features={features}
                    moreLink={'/features'}
                    titleId={'event.features.title'}
                    buttonId={'event.features.more'}
                />
                <BrandingSection image={brandingImage} />
                <MoreFeaturesSection features={moreFeatures} />
                <FeedbackSection feedback={feedback} />
                <ClientsSection clients={clients} />
                {/*<AssetsSection facebookImage={facebookImage} instagramImage={instagramImage} />*/}
                <ContactsSection titleId="home.contacts.title" />
            </main>
            <Footer />
        </FelaComponent>
    );
};

export default withPageContext(IndexPage);
